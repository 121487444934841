import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

const termsCondition = () => {
  return (
    <div
      style={{
        maxWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "#1b0a28",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Grid
        sx={{
          position: "relative",
          paddingTop: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12rem" },

          paddingLeft: { sm: "2rem", md: "2rem", lg: "10rem" },
          paddingRight: { sm: "2rem", md: "2rem", lg: "10rem" },
        }}
      >
        <Grid classes="content_gradient_sub"></Grid>
        <Grid classes="recent_gradient"></Grid>
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
            marginTop: "2rem",
            marginBottom: "5rem",
          }}
        />
        {/* <Grid classes="home_gradients_terms" /> */}
        <div
          style={{
            width: "181.201px",
            //   height: "680.82px",
            height: "750.425px",
            borderRadius: "893.425px",
            border: "1px solid #230E60",
            //  top: "56.6%",
            flexShrink: 0,
            right: "19%",
            background:
              "linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)",

            position: "absolute", // Position absolutely within the parent container

            // marginTop:'58rem',
            // marginTop:'65rem',
            transform: "rotate(87.26deg)",
            filter: "blur(90.56px)",

            gap: "0px",
            opacity: "0px",
          }}
        />
        {/* <Grid classes="home_gradients" /> */}
        <div
          style={{
            width: "241.6px",
            //   height: "680.82px",
            height: "750.425px",
            borderRadius: "893.425px",
            border: "1px solid #230E60",
            top: "40.6%",
            flexShrink: 0,
            right: "10%",
            background:
              "linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)",

            position: "absolute", // Position absolutely within the parent container

            //  marginTop:'10rem',
            transform: "rotate(87.26deg)",
            //  filter: 'blur(100.56px)',
            filter: "blur(95.56px)",

            gap: "0px",
            opacity: "0px",
          }}
        />
        {/* <Grid classes="recent_gradient_sub"></Grid> */}
        <div
          style={{
            width: "279px",
            //  marginTop:'130px',

            marginTop: "1080px",

            borderRadius: "500.667px",

            //   height: "680.82px",
            //  height: "400.45px",
            height: "650.45px",
            // top: "1239px",
            left: "0%",
            top: "0%",
            // top:'780px',
            // marginTop:"150px",
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(190px)",
            //   zIndex:-5,
            position: "absolute",
          }}
        />
        {/* <Grid classes="recent_gradient_sub_terms"></Grid> */}
        <div
          style={{
            width: "279px",
            //  marginTop:'130px',

            marginTop: "800px",

            borderRadius: "500.667px",

            //   height: "680.82px",
            //  height: "400.45px",
            height: "650.45px",
            // top: "1239px",
            left: "0%",
            top: "20%",
            // top:'780px',
            // marginTop:"150px",
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(190px)",
            //   zIndex:-5,
            position: "absolute",
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              position: "relative",
              paddingLeft: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
            }}
          >
            <Typography
              variant="h8"
              sx={{
                fontSize: {
                  xs: "30px",
                  sm: "38.574px",
                  md: "38.574px",
                  lg: "38.574px",
                }, // Responsive font size
              }}
            >
              Terms and Conditions
            </Typography>
          </Grid>
          <Grid
            sx={{
              //   paddingLeft: "8rem",
              paddingTop: "2rem",
              //   paddingRight: "8rem",
              paddingBottom: "5rem",

              paddingLeft: { xs: "16px", sm: "4rem", md: "5rem", lg: "8rem" },
              paddingRight: { xs: "16px", sm: "4rem", md: "5rem", lg: "8rem" },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
              }}
            >
              Please Read The Terms and Condition (EULA) Mentioned at Evangel
              <br />
              <br />
              Version 1.0
              <br />
              <br />
              The evangel. Stream website at https://www.evangel.stream is a
              copyrighted work belonging to Charisma Infotainment and its
              partners. Certain Site features may be subject to additional
              guidelines, terms, or rules, which will be posted on the Site in
              connection with such features.
              <br />
              <br />
              All such additional terms, guidelines, and rules are incorporated
              by reference into these Terms.
              <br />
              <br />
              These Terms of Use described the legally binding terms and
              conditions that oversee your use of the Site. BY LOGGING INTO THE
              SITE, YOU ARE COMPLIANT WITH THESE TERMS, and you represent that
              you have the authority and capacity to enter into these Terms. YOU
              SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU
              DISAGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT LOG
              INTO AND USE THE SITE.
              <br />
              <br />
              These terms require the use of arbitration (Section 10.2) on an
              individual basis to resolve disputes and also limit the remedies
              available to you in the event of a dispute.
            </Typography>

            <Typography variant="body2">Access to the Site</Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
              }}
            >
              Subject to these Terms. The company grants you a non-transferable,
              non-exclusive, revocable, limited license to access the Site
              solely for your own personal, noncommercial use.
              <br />
              <br />
              Certain Restrictions. The rights approved to you in these Terms
              are subject to the following restrictions:
              <br />
              (a) you shall not sell, rent, lease, transfer, assign, distribute,
              host, or otherwise commercially exploit the Site;
              <br />
              (b) you shall not change, make derivative works of, disassemble,
              reverse compile, or reverse engineer any part of the Site;
              <br />
              (c) you shall not access the Site to build a similar or
              competitive website; and
              <br />
              (d) except as expressly stated herein, no part of the Site may be
              copied, reproduced, distributed, republished, downloaded,
              displayed, posted, or transmitted in any form or by any means
              unless otherwise indicated, any future release, update, or other
              addition to functionality of the Site shall be subject to these
              Terms.
              <br />
              <br />
              All copyright and other proprietary notices on the Site must be
              retained on all copies thereof.
              <br />
              <br />
              The company reserves the right to change, suspend, or cease the
              Site with or without notice to you. You approved that Company will
              not be held liable to you or any third-party for any change,
              interruption, or termination of the Site or any part.
              <br />
              <br />
              Excluding any User Content that you may provide, you are aware
              that all the intellectual property rights, including copyrights,
              patents, trademarks, and trade secrets, in the Site and its
              content are owned by Company or Company’s suppliers. Note that
              these Terms and access to the Site do not give you any rights,
              title or interest in or to any intellectual property rights,
              except for the limited access rights expressed in Section 2.1.
              Company and its supplier's reserve all rights not granted in these
              Terms.
            </Typography>
            <Typography variant="body2">User Content</Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
              }}
            >
              User Content. "User Content" means any information and content
              that a user submits to the Site. You are exclusively responsible
              for your User Content. You bear all risks associated with the use
              of your User Content. You at this moment certify that your User
              Content does not violate our Acceptable Use Policy. You may not
              represent or imply to others that your User Content is in any way
              provided, sponsored or endorsed by Company. Because you alone are
              responsible for your User Content, you may expose yourself to
              liability. The company is not obliged to backup any User Content
              that you post; also, your User Content may be deleted at any time
              without prior notice to you. You are solely responsible for making
              your backup copies of your User Content if you desire. You at this
              moment grant to Company an irreversible, nonexclusive,
              royalty-free and fully-paid, worldwide license to reproduce,
              distribute, publicly display and perform, prepare derivative works
              of, incorporate into other works, and otherwise use and exploit
              your User Content, and to grant sublicenses of the preceding
              rights, solely to include your User Content in the Site. You at
              this moment irreversibly waive any claims and assertions of moral
              rights or attribution concerning your User Content.
              <br />
              <br />
              Acceptable Use Policy. The following terms constitute our
              "Acceptable Use Policy": You agree not to use the Site to collect,
              upload, transmit, display, or distribute any User Content (i) that
              violates any third-party right or any intellectual property or
              proprietary right; (ii) that is unlawful, harassing, abusive,
              tortious, threatening, harmful, invasive of another’s privacy,
              vulgar, defamatory, false, intentionally misleading, trade
              libellous, pornographic, obscene, patently offensive, promotes
              racism, bigotry, hatred, or physical harm of any kind against any
              group or individual; (iii) that is harmful to minors in any way;
              or (iv) that violates any law, regulation, or obligations or
              restrictions imposed by any third party.
              <br />
              <br />
              In addition, you agree not to: (i) upload, transmit, or distribute
              to or through the Site any software intended to damage or alter a
              computer system or data; (ii) send through the Site unsolicited or
              unauthorised advertising, promotional materials, junk mail, spam,
              chain letters, pyramid schemes, or any other form of duplicative
              or unsolicited messages; (iii) use the Site to harvest, collect,
              gather or assemble information or data regarding other users
              without their consent; (iv) interfere with, disrupt, or create an
              undue burden on servers or networks connected to the Site, or
              violate the regulations, policies or procedures of such networks;
              (v) attempt to gain unauthorised access to the Site, whether
              through password mining or any other means; (vi) harass or
              interfere with any other user’s use and enjoyment of the Site; or
              (vi) use software or automated agents or scripts to produce
              multiple accounts on the Site, or to generate automated searches,
              requests, or queries to the Site.
              <br />
              <br />
              We reserve the right to review any User Content and investigate
              and take appropriate action against you in our sole discretion if
              you violate the Acceptable Use Policy or any other provision of
              these Terms or otherwise create liability for us or any other
              person. Such action may include removing or modifying your User
              Content, terminating your Account by Section 8, and reporting you
              to law enforcement authorities.
              <br />
              <br />
              If you provide Company with any feedback or suggestions regarding
              the Site, you at this moment assign all rights to Company in such
              Feedback. You agree that Company shall have the right to use and
              fully exploit such Feedback and related information in any manner
              it believes appropriate. The company will treat any Feedback you
              provide to Company as non-confidential and non-proprietary.
              <br />
              <br />
              You agree to indemnify and hold Company and its officers,
              employees, and agents harmless, including costs and attorneys’
              fees, from any claim or demand made by any third party due to or
              arising out of (a) your use of the Site, (b) your violation of
              these Terms, (c) your violation of applicable laws or regulations
              or (d) your User Content. The company reserves the right to assume
              the exclusive defence and control of any matter for which you are
              required to indemnify us, and you agree to cooperate with our
              defence of these claims. You agree not to settle any case without
              the Company's prior written consent. The company will use
              reasonable efforts to notify you of any such claim, action or
              proceeding upon becoming aware of it.
              <br />
              <br />
              Third-Party Links. The Site may contain links to third-party
              websites and services and display advertisements for third
              parties. Such Third-Party Links are not under the control of the
              Company, and Company is not responsible for any Third-Party Links.
              The company provides access to these Third-Party Links only to
              convenience you. It does not review, approve, monitor, endorse,
              warrant, or make representations concerning Third-Party Links. You
              use all Third-Party Links at your own risk and should apply a
              suitable level of caution and discretion in doing so. When you
              click on any of the Third-Party Links, the applicable third
              party’s terms and policies apply, including the third party’s
              privacy and data gathering practices.
              <br />
              <br />
              Other Users. Each Site user is solely responsible for any of its
              User Content. Because we do not control User Content, you
              acknowledge and agree that we are not responsible for any User
              Content provided by you or others. You agree that the Company will
              not be liable for any loss or damage incurred due to any such
              interactions. If there is a dispute between you and any Site user,
              we are under no obligation to become involved.
              <br />
              <br />
              You at this moment release and forever discharge the Company and
              our officers, employees, agents, successors, and assigns from, and
              at this moment waive and relinquish, every past, present, and
              future dispute, claim, controversy, demand, right, obligation,
              liability, action and cause of action of every kind and nature,
              that has arisen or arises directly or indirectly out of, or that
              relates directly or indirectly to the Site. If you are a
              California resident, you at this moment waive California civil
              code section 1542 in connection with the preceding, which states:
              "a general release does not extend to claims which the creditor
              does not know or suspect to exist in his or her favour at the time
              of executing the release, which if known by him or she must have
              materially affected his or her settlement with the debtor."
              <br />
              <br />
              Cookies and Web Beacons. Like any other website, evangel. Stream
              uses ‘cookies. These cookies are used to store information,
              including visitors’ preferences and the pages on the website that
              the visitor accessed or visited. The data optimises the users’
              experience by customising our web page content based on visitors’
              browser type and other information.
            </Typography>
            <Typography variant="body2">Disclaimers</Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
              }}
            >
              The site is provided on an "as-is" and "as available" basis. The
              company and our suppliers expressly disclaim any warranties and
              conditions of any kind, whether express, implied, or statutory,
              including all warranties or conditions of merchantability, fitness
              for a particular purpose, title, quiet enjoyment, accuracy, or
              non-infringement. Our suppliers and we do not guarantee that the
              site will meet your requirements, will be available on an
              uninterrupted, timely, secure, or error-free basis, or will be
              accurate, reliable, free of viruses or other harmful code
              complete, legal, or safe. If applicable law requires any
              warranties concerning the site, all such warranties are limited in
              duration to ninety (90) days from the date of first use. Some
              jurisdictions do not allow the exclusion of implied warranties, so
              the above exclusion may not apply to you. Some jurisdictions do
              not allow limitations on how long an implied warranty lasts, so
              the above limitation may not apply to you.
            </Typography>
            <Typography variant="body2">Limitation on Liability</Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
              }}
            >
              To the maximum extent permitted by law, in no event shall Company
              or our suppliers be liable to you or any third-party for any lost
              profits, lost data, costs of procurement of substitute products,
              or any indirect, consequential, exemplary, incidental, special or
              punitive damages arising from or relating to these terms or your
              use of, or incapability to use the site even if the company has
              been advised of the possibility of such damages. Access to and use
              of the site is at your discretion and risk, and you will be solely
              responsible for any damage to your device or computer system or
              loss of data resulting from that place.
              <br />
              <br />
              To the maximum extent permitted by law, notwithstanding anything
              to the contrary contained herein, our liability to you for any
              damages arising from or related to this agreement will at all
              times be limited to a maximum of Thousand Rupee (₹ 1000). The
              existence of more than one claim will not enlarge this limit. You
              agree that our suppliers will have no liability of any kind
              arising from or relating to this agreement.
              <br />
              <br />
              Some jurisdictions do not allow the limitation or exclusion of
              liability for incidental or consequential damages, so the above
              limitation or exclusion may not apply to you.
              <br />
              <br />
              Term and Termination. Subject to this Section, these Terms will
              remain in full force and effect while you use the Site. We may
              suspend or terminate your rights to use the Site at any time for
              any reason at our sole discretion, including for any use of the
              Site in violation of these Terms. Upon termination of your rights
              under these Terms, your Account and right to access and use the
              Site will terminate immediately. You understand that any
              termination of your Account may involve deleting your User Content
              associated with your Account from our live databases. The company
              will not have any liability whatsoever for any termination of your
              rights under these Terms. Even after your rights under these Terms
              are terminated, the following provisions will remain in effect:
              Sections 2 through 2.5, Section 3, and Sections 4 through 10.
            </Typography>
            <Typography variant="body2">Copyright Policy.</Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
              }}
            >
              he company respects the intellectual property of others and asks
              that users of our Site do the same. In connection with our Site,
              we have adopted and implemented a policy respecting copyright law
              that provides for the removal of any infringing materials and for
              the termination of users of our online Site who are repeated
              infringers of intellectual property rights, including copyrights.
              Suppose you believe that one of our users is unlawfully infringing
              the copyright(s) in work through the use of our Site and wish to
              have the allegedly infringing material removed. In that case, the
              following information in the form of a written notification must
              be provided to our designated Copyright Agent:
              <br />
              <br />
              your physical or electronic signature; identification of the
              copyrighted work(s) that you claim to have been infringed;
              identification of the material on our services that you claim is
              infringing and that you request us to remove; sufficient
              information to permit us to locate such material; your address,
              telephone number, and e-mail address; a statement that you have a
              good faith belief that use of the objectionable material is not
              authorised by the copyright owner, its agent, or under the law;
              and a statement that the information in the notification is
              accurate and under penalty of perjury, that you are either the
              owner of the copyright that has allegedly been infringed or that
              you are authorised to act on behalf of the copyright owner. Please
              note that under the Indian IT Act, any misrepresentation of
              material fact in a written notification automatically subjects the
              complaining party to liability for any damages, costs, and
              attorney’s fees incurred by us in connection with the written
              notice and allegation of copyright infringement.
            </Typography>
            <Typography variant="body2">General</Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
              }}
            >
              These Terms are subject to occasional revision. If we make any
              substantial changes, we may notify you by sending you an e-mail to
              the last e-mail address you provided to us and prominently posting
              notice of the changes on our Site. You are responsible for
              providing us with your most current e-mail address. If the last
              e-mail address you have provided us is not valid, our dispatch of
              the e-mail containing such notice will constitute adequate notice
              of the changes described in the statement. Any changes to these
              Terms will be effective upon the earliest of thirty (30) calendar
              days following our dispatch of an e-mail notice to you or thirty
              (30) calendar days following our posting of notice of the changes
              on our Site. These changes will be effective immediately for new
              users of our Site. Continued use of our Site following notice of
              such changes shall indicate your acknowledgement of such changes
              and agreement to be bound by the terms and conditions of such
              changes. Dispute Resolution. Please read this Arbitration
              Agreement carefully. It is part of your contract with the Company
              and affects your rights. It contains procedures for MANDATORY
              BINDING ARBITRATION AND A CLASS ACTION WAIVER.
              <br />
              <br />
              Applicability of Arbitration Agreement. All claims and disputes in
              connection with the Terms or the use of any product or service
              provided by the Company that cannot be resolved informally or in
              small claims court shall be resolved by binding arbitration on an
              individual basis under the terms of this Arbitration Agreement.
              Unless otherwise agreed to, all arbitration proceedings shall be
              held in English. This Arbitration Agreement applies to you and the
              Company, and any subsidiaries, affiliates, agents, employees,
              predecessors in interest, successors, and assigns, as well as all
              authorised or unauthorised users or beneficiaries of services or
              goods provided under the Terms.
              <br />
              <br />
              Notice Requirement and Informal Dispute Resolution. Before either
              party may seek arbitration, the party must first send the other
              party a written Notice of Dispute describing the nature and basis
              of the claim or dispute and the requested relief. A Notice to the
              Company should be sent to Charisma Legal Cell. After the Notice is
              received, you and the Company may attempt to informally resolve
              the claim or dispute. If you and the Company do not resolve the
              claim or dispute within thirty (30) days after the Notice is
              received, either party may begin an arbitration proceeding. The
              amount of any settlement offer made by any party may not be
              disclosed to the arbitrator until after the arbitrator has
              determined the award amount to which either party is entitled.
              <br />
              <br />
              Electronic Communications. The communications between you and
              Company use electronic means, whether you use the Site or send us
              emails, or whether the Company posts notices on the Site or
              communicates with you via email. For contractual purposes, you (a)
              consent to receive communications from Company in an electronic
              form; and (b) agree that all terms and conditions, agreements,
              notices, disclosures, and other communications that Company
              provides to you electronically satisfy any legal obligation that
              such communications would satisfy if it were in a hard
              copywriting.
              <br />
              <br />
              Entire Terms. These Terms constitute the entire agreement between
              you and us regarding the use of the Site. Our failure to exercise
              or enforce any right or provision of these Terms shall not operate
              as a waiver of such right or provision. The section titles in
              these Terms are for convenience only and have no legal or
              contractual effect. The word "including" means "including without
              limitation". Suppose any provision of these Terms is held to be
              invalid or unenforceable. In that case, the other provisions of
              these Terms will be unimpaired. The invalid or unenforceable
              provision will be deemed modified to be valid and enforceable to
              the maximum extent permitted by law. Your relationship to Company
              is that of an independent contractor, and neither party is an
              agent or partner of the other. These Terms and your rights and
              obligations herein may not be assigned, subcontracted, delegated,
              or otherwise transferred by you without Company’s prior written
              consent, and any attempted assignment, subcontract, delegation, or
              transfer in violation of the preceding will be null and void. The
              company may freely assign these Terms. The terms and conditions
              outlined in these Terms shall be binding upon assignees.
              <br />
              <br />
              Paid Apple Users ( iPhone, iPad, Apple TV )
              <br />
              <br />
              Free Subscription for all registered users
              <br />
              <br />
              Paid Android Users ( Android phone , tablets , Android Tv )
              <br />
              <br />
              Free Subscription for all registered users
              <br />
              <br />
              * updated and effective from 1st Feb 2024
              <br />
              <br />
              Your Privacy. Listed at the footer of every page of the website
              <br />
              <br />
            </Typography>
            <Typography
              variant="body3"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
              }}
            >
              Copyright/Trademark Information. Copyright ©. All rights reserved.
              All trademarks, logos and service marks displayed on the Site are
              our property or the property of other third parties. You are not
              permitted to use these Marks without our prior written consent or
              the consent of such a third party that may own the Marks.
              <br />
              <br />
              Contact Information Address: Charisma Infotainment Corporate HQ
              Email: privacy@evangel.stream
              <br />
              <br />
              Charisma Legal and Compliance Cell email:
              legal@charismainfotainment.com
              <br />
              <br />
              Partner Support email: partners@evangel.stream
              <br />
              <br />
              Support: https://support.evangel.stream
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default termsCondition;
