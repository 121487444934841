import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import {
  get_historylist,
  Delete_history,
  Delete_History_All,
} from "./profileState";
import DeleteIcon from "../../../../pages/images/deleteicon.png";

import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import five from "../../../../pages/images/five.png";
// import four from "../../../../pages/images/400.png";
import CustomSnackbar from "../../../Snackbar/snackbar";
import Deletetoast from "../../../../pages/images/deletetoast.png";
import NoHistory from "../../../../pages/images/NohistoryData.png";

const History = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track hover
  const [history, setHistory] = useState([]);
  const [error500, setError500] = useState(false); // Add error state for 500 status
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarImage, setSnackbarImage] = React.useState(""); // Dynamic image URL
  const [error404, setError404] = useState(false); // Add error state for 500 status
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await get_historylist();

        setHistory(res.data);
      } catch (err) {
        if (err.response && err.response.status === 500) {
          setError500(true);
        } else if (err.response && err.response.status === 404) {
          setError404(true); // Handle 404 error
        } else if (err.response && err.response.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/");
          // Optionally reload the page to ensure UI state updates
          window.location.reload();
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickSnackbar = (message, imgSrc) => {
    setSnackbarMessage(message);
    setSnackbarImage(imgSrc); // Set the dynamic image
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };

  const handleDelete = async (watchhistory_id) => {
    try {
      const response = await Delete_history(watchhistory_id);

      if (response.status === 201) {
        // Check for successful deletion
        setHistory((prevList) =>
          prevList.filter((item) => item.watchhistory_id !== watchhistory_id)
        );
        handleClickSnackbar("History deleted successfully!", Deletetoast);
      } else {
        setSnackbarMessage("Failed to remove item from History");
        setSnackbarOpen(true);
      }
    } catch (error) {
      if (error.response) {
        setSnackbarMessage(
          `Failed to remove item: ${
            error.response.data.message || error.response.statusText
          }`
        );
      } else {
        setSnackbarMessage("Failed to remove item from History");
      }
      setSnackbarOpen(true);
    }
  };

  const handleDeleteAll = () => {
    Delete_History_All()
      .then((response) => {
        if (response.status === 201) {
          handleClickSnackbar("History deleted successfully!", Deletetoast);
          window.location.reload();
          // Refresh the page to reflect changes
          //
        } else if (response.status === 400) {
          handleClickSnackbar("No items to delete!", Deletetoast);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to remove items from History");
        setSnackbarOpen(true);
        // Handle error (e.g., show an error message to the user)
      });
  };

  const navigate = useNavigate();

  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };

  const handleClick = (content_id, content_type_id) => {
    // Construct the query string
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;

    // Encrypt the query string
    const encryptedQuery = encryptQueryString(queryString);

    // Navigate to the new page with the encrypted query string
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };

  return (
    <>
      <div
        style={{
          maxWidth: "100vw",
          minHeight: "100vh", // Changed height to minHeight to ensure the content can overflow if needed
          backgroundColor: "#1b0a28",
          position: "relative",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        {error500 && (
          <div
            style={{
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh", // Full viewport height to center the image vertically
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              zIndex: 10, // Ensure it appears above the normal content
              display: "flex",
            }}
          >
            <img
              src={five}
              alt="Error 500"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}

        {/* Render 404 Error Image */}
        {error404 && (
          <div
            style={{
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh", // Full viewport height to center the image vertically
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              zIndex: 10, // Ensure it appears above the normal content
              display: "flex",
            }}
          >
            <img
              // src={four}
              src={NoHistory}
              alt="Error 404"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}
        {!error500 && !error404 && (
          <>
            <div
              style={{
                width: "100%",

                height: "236px",
                top: "-70%",
                left: "-91.86", // Position from the left edge of the image

                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0.9%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 86.4%)",
                position: "absolute", // Position absolutely within the parent container
                filter: "blur(12px)",
              }}
            />

            <Grid
              sx={{
                position: "relative",
                paddingTop: {
                  xs: "8rem",
                  sm: "10rem",
                  md: "12rem",
                  lg: "12rem",
                },
                paddingLeft: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "5rem",
                  lg: "10rem",
                },
                paddingRight: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "5rem",
                  lg: "10rem",
                },
              }}
            >
              {/* <Grid classes="content_gradient_sub"></Grid> */}
              {/* <Grid classes="recent_gradient"></Grid> */}
              <div
                style={{
                  width: "549px",

                  borderRadius: "600.667px",

                  height: "600.45px",

                  left: "0%",
                  top: "0%",

                  gap: "0px",
                  opacity: "0px",
                  background: "rgba(118, 64, 232, 1)",
                  filter: "blur(170px)",

                  position: "absolute",
                }}
              />
              {/* <Grid classes="home_gradients_wishlist" /> */}
              <div
                style={{
                  // display: { xs: "flex", sm: "none", md: "none", lg: "none", xl: "none" } ,
                  // width: "181px",
                  width: "190.201px",
                  //   height: "680.82px",
                  height: "800.425px",
                  borderRadius: "893.425px",
                  border: "1px solid #230E60",
                  top: "10%",
                  flexShrink: 0,
                  right: "15%",
                  background:
                    "linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)",

                  position: "absolute", // Position absolutely within the parent container

                  // marginTop:'58rem',
                  // marginTop:'65rem',
                  transform: "rotate(87.26deg)",
                  filter: "blur(90.56px)",

                  gap: "0px",
                  opacity: "0px",
                }}
              />
              {/* <Grid classes="recent_gradient_sub"></Grid> */}
              <div
                style={{
                  width: "279px",
                  //  marginTop:'130px',

                  marginTop: "1080px",

                  borderRadius: "500.667px",

                  //   height: "680.82px",
                  //  height: "400.45px",
                  height: "650.45px",
                  // top: "1239px",
                  left: "0%",
                  top: "0%",
                  // top:'780px',
                  // marginTop:"150px",
                  gap: "0px",
                  opacity: "0px",
                  background: "rgba(118, 64, 232, 1)",
                  filter: "blur(190px)",
                  //   zIndex:-5,
                  position: "absolute",
                }}
              />

              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ position: "relative" }}
              >
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    marginBottom: "1rem",
                  }}
                >
                  <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: "1rem" }}>
                    <Typography variant="subTitle1">History</Typography>
                  </Grid>
                  {history.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{
                        position: "relative",
                        paddingBottom: "1rem",
                        textAlign: "end",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#CD59E2",
                          fontWeight: 600,
                          fontSize: "22px",
                          lineHeight: "26px",
                          fontFamily: "Inter !important",
                          float: "right",
                          textTransform: "initial",
                          position: "relative",
                          cursor: "pointer",
                          justifyContent: "end",
                          display: "flex",
                        }}
                        onClick={handleDeleteAll}
                      >
                        Clear all
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Divider
                style={{
                  border: "1px solid #64748B",
                  position: "relative",
                }}
              />

              {error500 && (
                <div
                  style={{
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100vh", // Full viewport height to center the image vertically
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    zIndex: 10, // Ensure it appears above the normal content
                    display: "flex",
                  }}
                >
                  <img
                    src={five}
                    alt="Error 500"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              )}

              {history.length === 0 && !error500 ? (
                <Grid
                  container
                  spacing={3}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "5rem", // Adjust spacing below divider
                    paddingBottom: "5rem", // Add some spacing below the image
                  }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={NoHistory}
                        alt="No Data"
                        // style={{ maxWidth: "50%", height: "auto" }}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={{ xs: 2, sm: 4, md: 3 }}
                  rowSpacing={{ xs: 2, sm: 6, md: 5 }}
                  columnSpacing={{ xs: 2, sm: 1, md: 1, lg: 3 }}
                  columns={{ xs: 4, sm: 12, md: 14, lg: 14 }}
                  justifyContent={"space-evenly"}
                  sx={{
                    paddingTop: "3rem",
                    paddingBottom: "3rem",
                    position: "relative",
                  }}
                >
                  {history?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        md={2.6}
                        lg={2.6}
                        xl={2.6}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onClick={() =>
                          handleClick(item.content_id, item.content_type_id)
                        }
                        style={{
                          marginBottom: "1rem",

                          position: "relative",
                          transition: "background-color 0.3s ease",
                          backgroundColor:
                            hoveredIndex === index
                              ? "rgba(255, 255, 255, 0.1)"
                              : "transparent", // Background with light transparency
                        }}
                      >
                        <div
                          style={{
                            width: "calc(100% + 20px)", // Increase width slightly to give a larger background
                            height: "calc(100% + 20px)", // Increase height slightly
                            backgroundColor:
                              hoveredIndex === index
                                ? "rgba(255, 255, 255, 0.1)"
                                : "transparent",
                            position: "absolute",
                            top: "-10px",
                            left: "-10px",
                            zIndex: -1, // Ensure the background stays behind the content
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={item.thumbnail}
                          alt={`Thumbnail ${index}`}
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      </Grid>

                      {/* Text Content Grid Item */}
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={6}
                        lg={6}
                        xl={9}
                        // sx={{ marginTop: { xl: "1.5rem" } }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{
                          marginBottom: "1rem",
                          position: "relative",
                          transition: "background-color 0.3s ease",
                          backgroundColor:
                            hoveredIndex === index
                              ? "rgba(255, 255, 255, 0.1)"
                              : "transparent", // Background with light transparency
                        }}
                      >
                        <div
                          style={{
                            width: "calc(100% + 20px)", // Increase width slightly to give a larger background
                            height: "calc(100% + 20px)", // Increase height slightly
                            backgroundColor:
                              hoveredIndex === index
                                ? "rgba(255, 255, 255, 0.1)"
                                : "transparent",
                            position: "absolute",
                            top: "-10px",
                            left: "-10px",
                            zIndex: -1, // Ensure the background stays behind the content
                            transition: "all 0.3s ease",
                            marginBottom: "30px",
                          }}
                        />
                        <Typography
                          variant="subTitle1"
                          sx={{
                            fontSize: {
                              xs: "18px",
                              sm: "20px",
                              md: "22px",
                              lg: "28px",
                            },
                            lineHeight: {
                              xs: "22px",
                              sm: "24px",
                              md: "26px",
                              lg: "28px",
                            },
                            paddingRight: {
                              sm: "1rem",
                              lg: "1rem",
                              md: "1rem",
                            },
                          }}
                        >
                          {item.content_title}
                        </Typography>

                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: {
                              xs: "16px",
                              sm: "18px",
                              md: "18px",
                              lg: "25.237px",
                            },
                            lineHeight: {
                              xs: "20px",
                              sm: "22px",
                              md: "24px",
                              lg: "26.336px",
                            },
                            paddingTop: {
                              xs: "0.5rem",
                              sm: "1rem",
                              md: "1rem",
                              lg: "1.5rem",
                            },
                            color:
                              "var(--White-White-80, rgba(249, 249, 249, 0.80))",
                            fontFamily: "Inter",
                            fontWeight: 400,
                          }}
                        >
                          {item.content_type_name}
                        </Typography>
                      </Grid>

                      {/* Button Grid Item */}
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={5.4}
                        lg={5.4}
                        xl={5.4}
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-end" },
                          flexDirection: "column",
                          alignItems: "end",
                          paddingTop: {
                            xs: "1rem",
                            sm: "2rem",
                            md: "3rem",
                            lg: "5rem",
                          },
                        }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{
                          marginBottom: "1rem",
                          position: "relative",
                          transition: "background-color 0.3s ease",
                          backgroundColor:
                            hoveredIndex === index
                              ? "rgba(255, 255, 255, 0.1)"
                              : "transparent", // Background with light transparency
                        }}
                      >
                        <div
                          style={{
                            width: "calc(100% + 20px)", // Increase width slightly to give a larger background
                            height: "calc(100% + 20px)", // Increase height slightly
                            backgroundColor:
                              hoveredIndex === index
                                ? "rgba(255, 255, 255, 0.1)"
                                : "transparent",
                            position: "absolute",
                            top: "-10px",
                            left: "-10px",
                            zIndex: -1, // Ensure the background stays behind the content
                            transition: "all 0.3s ease",
                          }}
                        />
                        <Grid item>
                          {hoveredIndex === index && (
                            <img
                              src={DeleteIcon}
                              alt="delete"
                              style={{
                                marginBottom: "3rem",
                                cursor: "pointer",

                                marginRight: "24px",
                              }} // Optional margin to separate from the button
                              onClick={() => handleDelete(item.watchhistory_id)}
                            />
                          )}
                        </Grid>

                        <Button
                          sx={{
                            height: "49px",
                            padding: "11px 18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "11px",
                            background: "#654C75",
                            textTransform: "initial",
                            fontSize: { xs: "16px", sm: "18px", md: "20px" },
                            fontWeight: 400,
                            fontFamily: "Inter",
                            color: "#FFF",
                            marginBottom: "10px",
                            marginRight: "24px",
                            "&:hover": {
                              background: "#654C75",
                            },
                          }}
                        >
                          {item.content_duration}
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              )}
            </Grid>

            <CustomSnackbar
              open={snackbarOpen}
              onClose={handleCloseSnackbar}
              message={snackbarMessage}
              autoHideDuration={5000}
              imgSrc={snackbarImage} // Dynamically pass the image URL
            />
          </>
        )}
      </div>
    </>
  );
};

export default History;
