import axiosInstance from "../../axiosInstance";
import { apiEndpoint } from "../../components/data/config";

export const get_movie_video = async (content_id, content_type_id) => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/app_video_playback/${content_id}/${content_type_id}`,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config); // Use axiosInstance instead of axios
};
export const get_tv_video = async (content_id, content_type_id, episode_id) => {
  let url = `${apiEndpoint}/api/app_video_playback/${content_id}/${content_type_id}`;
  if (episode_id) {
    url = url + `/${episode_id}`;
  }

  const config = {
    method: "get",
    url,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config); // Use axiosInstance instead of axios
};

export const get_video_data = async (content_id, content_type_id) => {
  try {
    const response = await axiosInstance.get(
      `/api/app_video_playback/${content_id}/${content_type_id}`
    );

    return response;
  } catch (error) {
    console.error("Error in get_videoPlayer function:", error);
    throw error; // Re-throw error to handle it in the calling component
  }
};
